import React from "react";
import { CalendarDays } from "lucide-react";

const DateRangeDisplay = () => {
  // Calculate dates for last 30 days
  const endDate = new Date();
  const startDate = new Date();
  startDate.setDate(endDate.getDate() - 29); // -29 to include today (30 days total)

  // Format dates in German style
  const formatDate = (date) => {
    return date.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  return (
    <div className="flex items-center gap-2 border border-gray-200 rounded-2xl dark:border dark:border-[#5E5E5E] dashboardShadow dark:text-white px-4 py-2 text-sm text-gray-700 my-5">
      <CalendarDays className="h-4 w-4 text-gray-500" />
      <span>
        {formatDate(startDate)} bis {formatDate(endDate)}
      </span>
    </div>
  );
};

export default DateRangeDisplay;
