import React, { useEffect } from "react";
import annaImage from "src/knownFrom/Anna_Zuschnitt.png";
import oskarImage from "src/knownFrom/Oskar_Zuschnitt.jpg";

const flowPeople = [
  {
    name: "Oskar Bösener",
    role: "Chief Executive Officer",
    image: oskarImage,
  },
  {
    name: "Anna Uhlig",
    role: "Chief Executive Officer",
    image: annaImage,
  },
];

function LandingPageContact() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section
      className="lg:py-28 py-10 lg:max-w-[1216px] lg:mx-auto flex lg:flex-row flex-col px-4 justify-between items-start gap-10"
      id="contact"
    >
      <div className="flex flex-col items-start justify-start gap-10">
        <h2 className="text-4xl lg:text-flow-48 font-light leading-snug dark:text-white">
          <span className="text-flowPrimary">Wir sind hier, um zuzuhören.</span>
          <br />
          Kontaktiere uns jederzeit gerne.
        </h2>
        <div className="grid lg:grid-cols-2 grid-cols-1 w-full lg:w-auto lg:justify-items-start justify-items-center gap-8">
          {flowPeople.map((person) => (
            <div
              key={person.name}
              className="flex flex-col items-center justify-center lg:max-w-56"
            >
              <img
                src={person.image}
                alt={person.name}
                className="rounded-full w-40 h-40 mb-5 object-cover"
              />
              <h3 className="text-white leading-snug mb-2">{person.name}</h3>
              <p className="text-sm font-light text-[#C1C1C1]">{person.role}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center items-center w-full lg:w-1/2">
        <div
          className="calendly-inline-widget w-full"
          data-url="https://calendly.com/flow-in/google-ads-audit?hide_event_type_details=1&hide_gdpr_banner=1&text_color=1e1e1e&primary_color=34b2ff"
          style={{
            minWidth: "320px",
            height: "650px",
          }}
        >
          {/* Add a default style tag to ensure iframe fills container */}
          <style>{`
            .calendly-inline-widget iframe {
              width: 100% !important;
              height: 100% !important;
              min-height: 650px !important;
            }
          `}</style>
        </div>
      </div>
    </section>
  );
}

export default LandingPageContact;
