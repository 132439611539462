import React from "react";

const ContactCard = ({ variant, title, subtitle, buttonText, imageUrl }) => {
  return (
    <div className="flowContactGradient dashboardShadow rounded-2xl text-white flex flex-col items-center gap-4 p-8  h-full justify-center w-full">
      {variant === "image" && imageUrl && (
        <div className="mb-4">
          <img
            src={imageUrl}
            alt="Contact"
            className="w-16 h-16 rounded-full object-cover"
          />
        </div>
      )}
      <h2 className="text-2xl font-medium">{title}</h2>
      <p className="text-center text-flow-12 tracking-[0.48px] max-w-60">
        {subtitle}
      </p>
      <a
        href="#contact"
        className="bg-transparent border border-white text-white py-2 px-6 rounded-lg  transition-colors duration-300"
      >
        {buttonText}
      </a>
    </div>
  );
};

export default ContactCard;
