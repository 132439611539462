import React from "react";

const UserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M15.9987 15.9993C19.6806 15.9993 22.6654 13.0146 22.6654 9.33268C22.6654 5.65078 19.6806 2.66602 15.9987 2.66602C12.3168 2.66602 9.33203 5.65078 9.33203 9.33268C9.33203 13.0146 12.3168 15.9993 15.9987 15.9993Z"
      stroke="#34B2FF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.9987 15.9993C19.6806 15.9993 22.6654 13.0146 22.6654 9.33268C22.6654 5.65078 19.6806 2.66602 15.9987 2.66602C12.3168 2.66602 9.33203 5.65078 9.33203 9.33268C9.33203 13.0146 12.3168 15.9993 15.9987 15.9993Z"
      stroke="url(#paint0_linear_8120_65)"
      stroke-opacity="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.9987 15.9993C19.6806 15.9993 22.6654 13.0146 22.6654 9.33268C22.6654 5.65078 19.6806 2.66602 15.9987 2.66602C12.3168 2.66602 9.33203 5.65078 9.33203 9.33268C9.33203 13.0146 12.3168 15.9993 15.9987 15.9993Z"
      stroke="url(#paint1_radial_8120_65)"
      stroke-opacity="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.54688 29.3333C4.54688 24.1733 9.6802 20 16.0002 20C17.2802 20 18.5202 20.1733 19.6802 20.4933"
      stroke="#34B2FF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.54688 29.3333C4.54688 24.1733 9.6802 20 16.0002 20C17.2802 20 18.5202 20.1733 19.6802 20.4933"
      stroke="url(#paint2_linear_8120_65)"
      stroke-opacity="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.54688 29.3333C4.54688 24.1733 9.6802 20 16.0002 20C17.2802 20 18.5202 20.1733 19.6802 20.4933"
      stroke="url(#paint3_radial_8120_65)"
      stroke-opacity="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.3346 23.9993C29.3346 24.9993 29.0547 25.946 28.5613 26.746C28.2813 27.226 27.9213 27.6527 27.508 27.9993C26.5746 28.8393 25.348 29.3327 24.0013 29.3327C22.0546 29.3327 20.3613 28.2927 19.4413 26.746C18.948 25.946 18.668 24.9993 18.668 23.9993C18.668 22.3193 19.4413 20.8127 20.668 19.8394C21.588 19.106 22.748 18.666 24.0013 18.666C26.948 18.666 29.3346 21.0527 29.3346 23.9993Z"
      stroke="#34B2FF"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.3346 23.9993C29.3346 24.9993 29.0547 25.946 28.5613 26.746C28.2813 27.226 27.9213 27.6527 27.508 27.9993C26.5746 28.8393 25.348 29.3327 24.0013 29.3327C22.0546 29.3327 20.3613 28.2927 19.4413 26.746C18.948 25.946 18.668 24.9993 18.668 23.9993C18.668 22.3193 19.4413 20.8127 20.668 19.8394C21.588 19.106 22.748 18.666 24.0013 18.666C26.948 18.666 29.3346 21.0527 29.3346 23.9993Z"
      stroke="url(#paint4_linear_8120_65)"
      stroke-opacity="0.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.3346 23.9993C29.3346 24.9993 29.0547 25.946 28.5613 26.746C28.2813 27.226 27.9213 27.6527 27.508 27.9993C26.5746 28.8393 25.348 29.3327 24.0013 29.3327C22.0546 29.3327 20.3613 28.2927 19.4413 26.746C18.948 25.946 18.668 24.9993 18.668 23.9993C18.668 22.3193 19.4413 20.8127 20.668 19.8394C21.588 19.106 22.748 18.666 24.0013 18.666C26.948 18.666 29.3346 21.0527 29.3346 23.9993Z"
      stroke="url(#paint5_radial_8120_65)"
      stroke-opacity="0.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.9219 24L23.2419 25.32L26.0819 22.6934"
      stroke="#34B2FF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.9219 24L23.2419 25.32L26.0819 22.6934"
      stroke="url(#paint6_linear_8120_65)"
      stroke-opacity="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.9219 24L23.2419 25.32L26.0819 22.6934"
      stroke="url(#paint7_radial_8120_65)"
      stroke-opacity="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8120_65"
        x1="11.2614"
        y1="4.64249"
        x2="21.0967"
        y2="13.7562"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.47" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_8120_65"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.3516 6.02288) rotate(53.9993) scale(7.44556)"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_8120_65"
        x1="6.73676"
        y1="21.3835"
        x2="13.1063"
        y2="30.9536"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.47" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint3_radial_8120_65"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.2441 22.3498) rotate(40.3261) scale(6.51557 6.75984)"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint4_linear_8120_65"
        x1="20.2115"
        y1="20.2472"
        x2="28.0797"
        y2="27.5382"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.47" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint5_radial_8120_65"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.6837 21.3515) rotate(53.9993) scale(5.95645)"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint6_linear_8120_65"
        x1="22.5239"
        y1="23.0827"
        x2="24.3323"
        y2="25.7368"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.47" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint7_radial_8120_65"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(23.488 23.3547) rotate(40.9915) scale(1.80901 1.8835)"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

const ChecklistIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
  >
    <path
      d="M13.0781 19.6009L15.0781 21.6009L20.4115 16.2676"
      stroke="#34B2FF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.0781 19.6009L15.0781 21.6009L20.4115 16.2676"
      stroke="url(#paint0_linear_8053_3036)"
      stroke-opacity="0.5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.0781 19.6009L15.0781 21.6009L20.4115 16.2676"
      stroke="url(#paint1_radial_8053_3036)"
      stroke-opacity="0.5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.9987 7.99935H19.332C21.9987 7.99935 21.9987 6.66602 21.9987 5.33268C21.9987 2.66602 20.6654 2.66602 19.332 2.66602H13.9987C12.6654 2.66602 11.332 2.66602 11.332 5.33268C11.332 7.99935 12.6654 7.99935 13.9987 7.99935Z"
      stroke="#34B2FF"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.9987 7.99935H19.332C21.9987 7.99935 21.9987 6.66602 21.9987 5.33268C21.9987 2.66602 20.6654 2.66602 19.332 2.66602H13.9987C12.6654 2.66602 11.332 2.66602 11.332 5.33268C11.332 7.99935 12.6654 7.99935 13.9987 7.99935Z"
      stroke="url(#paint2_linear_8053_3036)"
      stroke-opacity="0.5"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.9987 7.99935H19.332C21.9987 7.99935 21.9987 6.66602 21.9987 5.33268C21.9987 2.66602 20.6654 2.66602 19.332 2.66602H13.9987C12.6654 2.66602 11.332 2.66602 11.332 5.33268C11.332 7.99935 12.6654 7.99935 13.9987 7.99935Z"
      stroke="url(#paint3_radial_8053_3036)"
      stroke-opacity="0.5"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.9974 5.35938C26.4374 5.59938 28.6641 7.23937 28.6641 13.3327V21.3327C28.6641 26.666 27.3307 29.3327 20.6641 29.3327H12.6641C5.9974 29.3327 4.66406 26.666 4.66406 21.3327V13.3327C4.66406 7.25271 6.89073 5.59938 11.3307 5.35938"
      stroke="#34B2FF"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.9974 5.35938C26.4374 5.59938 28.6641 7.23937 28.6641 13.3327V21.3327C28.6641 26.666 27.3307 29.3327 20.6641 29.3327H12.6641C5.9974 29.3327 4.66406 26.666 4.66406 21.3327V13.3327C4.66406 7.25271 6.89073 5.59938 11.3307 5.35938"
      stroke="url(#paint4_linear_8053_3036)"
      stroke-opacity="0.5"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.9974 5.35938C26.4374 5.59938 28.6641 7.23937 28.6641 13.3327V21.3327C28.6641 26.666 27.3307 29.3327 20.6641 29.3327H12.6641C5.9974 29.3327 4.66406 26.666 4.66406 21.3327V13.3327C4.66406 7.25271 6.89073 5.59938 11.3307 5.35938"
      stroke="url(#paint5_radial_8053_3036)"
      stroke-opacity="0.5"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8053_3036"
        x1="14.1393"
        y1="17.0582"
        x2="17.9718"
        y2="21.9413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.47" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_8053_3036"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.8389 17.6103) rotate(45.028) scale(3.40576 3.581)"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_8053_3036"
        x1="12.8756"
        y1="3.4566"
        x2="16.1733"
        y2="9.56826"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.47" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint3_radial_8053_3036"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.3477 4.00876) rotate(34.5347) scale(4.25012 4.17392)"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint4_linear_8053_3036"
        x1="8.137"
        y1="8.91307"
        x2="25.8223"
        y2="25.3192"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.47" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint5_radial_8053_3036"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.6994 11.395) rotate(53.969) scale(13.3923 13.3969)"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

const LinkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
  >
    <path
      d="M13.7271 18.6658C11.7191 16.6498 11.9004 13.1991 14.1311 10.9605L20.5951 4.47246C22.8258 2.23246 26.2618 2.05113 28.2711 4.06579C30.2804 6.08046 30.0978 9.53246 27.8671 11.7725L24.6351 15.0165"
      stroke="#34B2FF"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M13.7271 18.6658C11.7191 16.6498 11.9004 13.1991 14.1311 10.9605L20.5951 4.47246C22.8258 2.23246 26.2618 2.05113 28.2711 4.06579C30.2804 6.08046 30.0978 9.53246 27.8671 11.7725L24.6351 15.0165"
      stroke="url(#paint0_linear_8053_3046)"
      stroke-opacity="0.5"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M13.7271 18.6658C11.7191 16.6498 11.9004 13.1991 14.1311 10.9605L20.5951 4.47246C22.8258 2.23246 26.2618 2.05113 28.2711 4.06579C30.2804 6.08046 30.0978 9.53246 27.8671 11.7725L24.6351 15.0165"
      stroke="url(#paint1_radial_8053_3046)"
      stroke-opacity="0.5"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M18.9396 13.332C20.9476 15.348 20.7663 18.7987 18.5356 21.0374L15.3036 24.2814L12.0716 27.5254C9.84094 29.7654 6.40494 29.9467 4.3956 27.932C2.38627 25.9174 2.56894 22.4654 4.7996 20.2254L8.0316 16.9814"
      stroke="#34B2FF"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M18.9396 13.332C20.9476 15.348 20.7663 18.7987 18.5356 21.0374L15.3036 24.2814L12.0716 27.5254C9.84094 29.7654 6.40494 29.9467 4.3956 27.932C2.38627 25.9174 2.56894 22.4654 4.7996 20.2254L8.0316 16.9814"
      stroke="url(#paint2_linear_8053_3046)"
      stroke-opacity="0.5"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M18.9396 13.332C20.9476 15.348 20.7663 18.7987 18.5356 21.0374L15.3036 24.2814L12.0716 27.5254C9.84094 29.7654 6.40494 29.9467 4.3956 27.932C2.38627 25.9174 2.56894 22.4654 4.7996 20.2254L8.0316 16.9814"
      stroke="url(#paint3_radial_8053_3046)"
      stroke-opacity="0.5"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8053_3046"
        x1="14.8405"
        y1="5.03775"
        x2="26.6768"
        y2="16.9208"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.47" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_8053_3046"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.858 6.6942) rotate(51.7908) scale(9.19893 9.40177)"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_8053_3046"
        x1="5.50843"
        y1="15.7038"
        x2="17.3448"
        y2="27.5868"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.47" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint3_radial_8053_3046"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.526 17.3602) rotate(51.7908) scale(9.19893 9.40177)"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

const SetupSteps = () => {
  const steps = [
    {
      icon: <UserIcon />,
      title: "Login mit Google",
      description: "Verbinden Sie sich mit Ihrem Google-Konto",
    },
    {
      icon: <ChecklistIcon />,
      title: "Login Popup bestätigen",
      description: "Folgen Sie den Anweisungen im Login-Popup",
    },
    {
      icon: <LinkIcon />,
      title: "Ads Account verbinden",
      description: "Verbinden Sie Ihr Google Ads Konto",
    },
    {
      icon: <LinkIcon />,
      title: "Servereinrichtung bestätigen",
      description: "Die Server-Einstellungen werden automatisch vorgenommen",
    },
  ];

  return (
    <div className="w-full mx-auto mt-8">
      <h2 className="text-xl font-semibold mb-2 dark:text-white">
        Nächste Schritte zur Einrichtung
      </h2>
      <p className="text-gray-600 dark: dark:text-white mb-6">
        Wir führen Sie durch die folgenden Schritte
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {steps.map((step, index) => (
          <div
            key={index}
            className="p-8 bg-[#1a1b1d] rounded-3xl shadow border border-[#5e5e5e] flex-col justify-start items-start inline-flex"
          >
            <div className="w-12 h-12 rounded-full bg-[#1a1b1d] dark:bg-[#1a1b1d] flex items-center justify-center mb-3 text-blue-500">
              {step.icon}
            </div>
            <h3 className="text-white text-base font-medium font-inter leading-tight tracking-wide">
              {step.title}
            </h3>
            <p className="text-white text-xs font-normal font-inter leading-tight tracking-wide mt-2">
              {step.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SetupSteps;
