import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const services = [
  {
    title: 'Strategische Planung & Entwicklung "No Brainer Offer"',
    description:
      "Eine effektive Google Ads-Kampagne beginnt mit einer fundierten strategischen Planung. Wir analysieren deine Zielgruppe, ihre Bedürfnisse und das Wettbewerbsumfeld, um ein Angebot zu entwickeln, das sich klar von der Konkurrenz abhebt. Dabei liegt der Fokus auf einem einfachen, klar verständlichen Mehrwert, der potenzielle Kunden überzeugt. Dieses „No Brainer Offer“ ist darauf ausgelegt, deine gewünschten Ziele zu erreichen und gleichzeitig effizient mit deinem Budget umzugehen. ",
    image: "/path-to-strategic-planning-image.png",
  },
  {
    title: "Aufsetzen des Tracking nach Google Vorgabe",
    description:
      "Ein korrekt eingerichtetes Tracking ist essenziell, um die Leistung deiner Google Ads-Kampagnen präzise zu messen. Wir richten das Tracking gemäß den aktuellen Google-Vorgaben ein und implementieren wichtige Features wie Bestellungen oder Kontaktanfragen als auch Scroll-Tiefe oder Video-Aufrufraten. So erhältst du einen transparenten Überblick über alle relevanten Kennzahlen, wie Klicks, Conversions und ROI (ROAS). Mit diesen Daten kannst du fundierte Entscheidungen treffen und deine Kampagnen nachhaltig optimieren.",
    image: "/path-to-tracking-setup-image.png",
  },
  {
    title: "Expertise in allen Google Formaten (erstellen & schalten)",
    description:
      "Google Ads bietet eine Vielzahl von Anzeigenformaten, darunter Suchanzeigen, Display-Anzeigen, Shopping-Kampagnen und Video-Ads. Wir verfügen über umfassende Erfahrung in der strategischen Planung und operativen Umsetzung dieser Formate. Jedes Format wird individuell auf deine Ziele abgestimmt und optimal in deine Kampagne integriert. Durch die Zusammenarbeit mit Google kennen wir die Best Practices, welche das Maximum deiner Kampagnen herausholen. Dadurch stellen wir sicher, dass deine Anzeigen zielgerichtet eingesetzt werden, um Reichweite, Relevanz und Conversions zu maximieren.",
    image: "/path-to-google-formats-image.png",
  },
  {
    title: "Wöchentliches & monatliches Reporting",
    description:
      "Wir halten dich wöchentlich über unsere Google Ads-Kampagnen auf dem Laufenden. Verfolge wichtige Kennzahlen, analysiere Leistungstrends und optimiere deine Werbestrategie, um den ROI zu maximieren. Mit unseren monatlichen Reportings erhältst du tiefere Einblicke in die Leistung deiner Google Ads. Dadurch unterstützen wir dich, Verbesserungsmöglichkeiten zu identifizieren, deine Zielgruppenauswahl zu verfeinern und deine Anzeigenkreationen für bessere Ergebnisse zu optimieren.",
    image: "/path-to-reporting-image.png",
  },
];

const ServiceAccordion = () => {
  const [expandedIndex, setExpandedIndex] = useState(0);

  return (
    <div className="max-w-[1216px] mx-auto py-32 flex flex-col gap-16 px-4">
      <h2 className="text-4xl lg:text-5xl font-light pb-8 border-b border-b-[#666] text-center lg:text-left leading-snug">
        Entdecke die Macht von Google Ads mit <br />
        unseren{" "}
        <span className="text-flowPrimary">individuellen Leistungen.</span>
      </h2>

      <div className="flex gap-8">
        {/* Left side - Image */}
        <div className="w-1/3 hidden lg:block">
          <AnimatePresence mode="wait">
            <motion.img
              key={expandedIndex}
              src={services[expandedIndex].image}
              alt={services[expandedIndex].title}
              className="w-full h-auto rounded-lg shadow-lg"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            />
          </AnimatePresence>
        </div>

        {/* Right side - Accordion */}
        <div className="lg:w-2/3 w-full space-y-8 relative">
          {services.map((service, index) => (
            <motion.div key={index} className="pb-8 relative" initial={false}>
              <motion.header
                className="flex justify-between items-center cursor-pointer"
                onClick={() => setExpandedIndex(index)}
              >
                <h3 className="text-2xl text-white leading-snug">
                  {service.title}
                </h3>
                <motion.span
                  className="text-3xl text-flowWhite"
                  animate={{ rotate: expandedIndex === index ? 45 : 0 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                  >
                    <path
                      d="M9.00091 17.9853L9.00091 1.01472M0.515625 9.5H17.4862"
                      stroke="white"
                      strokeWidth="0.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </motion.span>
              </motion.header>

              <AnimatePresence initial={false}>
                {expandedIndex === index && (
                  <>
                    <motion.div
                      key="content"
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "1rem" },
                        collapsed: { opacity: 0, height: 0, marginTop: 0 },
                      }}
                      transition={{ duration: 0.3, ease: "easeInOut" }}
                      className="overflow-hidden"
                    >
                      <p className="text-flowWhite font-light tracking-wide leading-snug">
                        {service.description}
                      </p>
                    </motion.div>
                    <motion.div
                      key="border"
                      className="absolute bottom-0 left-0 h-[1px] bg-flowPrimary"
                      initial={{ width: 0 }}
                      animate={{ width: "100%" }}
                      transition={{ duration: 0.5, ease: "easeInOut" }}
                    />
                  </>
                )}
              </AnimatePresence>
              {expandedIndex !== index && (
                <div className="absolute bottom-0 left-0 w-full h-[1px] bg-[#666]" />
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceAccordion;
