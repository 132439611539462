import React, { useEffect, useState } from "react";
import QualityScoreDisplay from "./QualityScoreDisplay";
import ContactCard from "./ContactCard";

const QualityScoreCard = ({ score }) => {
  const getScoreText = (score) => {
    if (score >= 1 && score <= 4) {
      return {
        headline: "Unterdurchschnittlicher Qualitätsfaktor",
        text: "Ihr aktueller Qualitätsfaktor liegt unter dem Branchendurchschnitt, was auf Verbesserungsbedarf bei Ihren Anzeigen und Keywords hindeutet. Testen Sie neue Anzeigentexte, setzen Sie auf präzisere Keyword-Optionen und sortieren Sie wenig relevante Suchbegriffe konsequent aus. Testen Sie weitere Gebotsstrategien, um den Fokus vermehrt auf die conversion-basierten KPIs (Kennzahlen) zu legen. Die folgenden Diagramme unterstützen Sie dabei, gezielt Maßnahmen abzuleiten und Ihre Kampagnenleistung nachhaltig zu steigern.",
      };
    } else if (score >= 5 && score <= 8) {
      return {
        headline: "Durchschnittlicher Qualitätsfaktor",
        text: "Ihr Konto weist einen durchschnittlichen Qualitätsfaktor auf, der noch Luft nach oben lässt. Verfeinern Sie Ihre Keyword-Auswahl, testen Sie alternative Anzeigentexte und arbeiten Sie mit verschiedenen Anzeigenformaten oder auch Kampagnentypen, um die Relevanz für potenzielle Kunden zu erhöhen. Auch ein gezielter Einsatz von Ausschluss-Keywords und regelmäßige A/B-Tests für Ihre Anzeigen können die Performance anheben. Mithilfe der folgenden Diagramme identifizieren Sie schnell die Bereiche mit dem größten Optimierungspotenzial.",
      };
    } else if (score >= 9 && score <= 10) {
      return {
        headline: "Überdurchschnittlicher Qualitätsfaktor",
        text: "Ihr Qualitätsfaktor ist bereits überdurchschnittlich – eine solide Basis, um noch mehr aus Ihren Kampagnen herauszuholen. Nutzen Sie Zielgruppenlisten, Segmentierungen nach Gerät oder Standort und probieren Sie verbesserte Keyword-Kombinationen aus, um Ihre Klick- und Conversionraten weiter zu steigern. Feintuning bei Anzeigenassets, Anzeigengruppenstruktur und Gebotsstrategien kann das bereits hohe Niveau noch weiter anheben und skalieren. Die folgenden Diagramme zeigen Ihnen die Bereiche, in denen zusätzliche Justierungen zur weiteren Performance-Steigerung führen.",
      };
    } else {
      return {
        headline: "Ungültiger Score",
        text: "Bitte überprüfen Sie den eingegebenen Wert. Der Quality Score sollte zwischen 1 und 10 liegen.",
      };
    }
  };

  const scoreInfo = getScoreText(score);

  return (
    <div className="flex flex-col lg:flex-row justify-center items-center w-full lg:justify-between  lg:items-stretch gap-8">
      <div
        className="flex flex-col lg:flex-row gap-9 items-center dark:border dark:border-[#5E5E5E] rounded-2xl p-8 dashboardShadow flex-shrink-0 lg:w-2/3"
        id="quality-score-section"
      >
        <QualityScoreDisplay score={score} />
        <div className="flex flex-col gap-3 dark:text-white text-[#333] lg:max-w-lg ">
          <h2 className="text-flow-20 text-center tracking-[0.8px]">
            {scoreInfo.headline}
          </h2>
          <p className=" text-flow-12 font-light text-center  tracking-[0.48px] leading-relaxed">
            {scoreInfo.text}
          </p>
        </div>
      </div>
      <div className="lg:w-1/3 w-full">
        <ContactCard
          title="Optimiere die Leistung"
          subtitle="Mit Best Practices und Learnings, die unsere Experten durch jahrelange Erfahrung und dem stetigen Austausch mit Google testen und sammeln konnten"
          buttonText="Termin vereinbaren"
        />
      </div>
    </div>
  );
};

export default QualityScoreCard;
