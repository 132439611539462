import React from "react";
import Marquee from "react-fast-marquee";

const logos = [
  { src: "/marquee/Bentley.png", alt: "Bentley", height: "h-6" },
  { src: "/marquee/CFaces.png", alt: "CFaces", height: "h-4" },
  { src: "/marquee/Evernest.png", alt: "Evernest", height: "h-4" },
  { src: "/marquee/fritzi.png", alt: "Fritzi", height: "h-4" },
  { src: "/marquee/Glasprofi24.png", alt: "Glasprofi24", height: "h-4" },
  { src: "/marquee/Innersense.png", alt: "Innersense", height: "h-4" },
  {
    src: "/marquee/JohnMasterOrganics.png",
    alt: "John Master Organics",
    height: "h-5",
  },
  { src: "/marquee/Medudy.png", alt: "Medudy", height: "h-4" },
  { src: "/marquee/organtic.png", alt: "Organtic", height: "h-4" },
  { src: "/marquee/osann.png", alt: "Osann", height: "h-2" },
  { src: "/marquee/Seeberger.png", alt: "Seeberger", height: "h-4" },
  { src: "/marquee/SignalIduna.png", alt: "Signal Iduna", height: "h-4" },
  { src: "/marquee/Stupidtasty.png", alt: "Stupidtasty", height: "h-4" },
  { src: "/marquee/vowls.png", alt: "Vowls", height: "h-4" },
  { src: "/marquee/WithinMood.png", alt: "Within Mood", height: "h-4" },
];

const LogoSection = ({ direction = "left" }) => (
  <Marquee gradient={false} speed={40} autoFill={true} direction={direction}>
    <div className="flex items-center space-x-16 mx-8">
      {logos.map((logo, index) => (
        <img
          key={index}
          src={logo.src}
          alt={logo.alt}
          className={`${logo.height} object-contain`}
        />
      ))}
    </div>
  </Marquee>
);

const LandingPageMarquee = ({ hidePY = false }) => (
  <div
    className={`flex flex-col justify-center items-center gap-5 ${
      hidePY ? "" : "py-40"
    }`}
  >
    <LogoSection direction="left" />
    <LogoSection direction="right" />
  </div>
);

export default LandingPageMarquee;
