import React from "react";

const ContactAndInfo = () => {
  return (
    <div className="flex lg:flex-row flex-col gap-8 h-full">
      <div className="flex flex-col items-center justify-center py-8 lg:px-20 px-8 flowContactGradient rounded-2xl lg:w-1/3 flex-shrink-0">
        <img
          src="/images/oskar.png"
          alt="Oskar Bösener - CEO - FLOW"
          className="size-24 rounded-full object-cover"
        />
        <h2 className="text-flow-20 tracking-[0.8px] text-white mt-4">
          Optimiere die Leistung
        </h2>
        <a
          href="google-com"
          className="flex flex-row items-center justify-center gap-2 text-white text-flow-12 mt-4"
        >
          <span>Termin mit Oskar vereinbaren</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="8"
            viewBox="0 0 33 8"
            fill="none"
          >
            <path
              d="M32.3536 4.35355C32.5488 4.15829 32.5488 3.84171 32.3536 3.64645L29.1716 0.464466C28.9763 0.269204 28.6597 0.269204 28.4645 0.464466C28.2692 0.659728 28.2692 0.976311 28.4645 1.17157L31.2929 4L28.4645 6.82843C28.2692 7.02369 28.2692 7.34027 28.4645 7.53553C28.6597 7.7308 28.9763 7.7308 29.1716 7.53553L32.3536 4.35355ZM0 4.5L32 4.5V3.5L0 3.5V4.5Z"
              fill="white"
            />
          </svg>
        </a>
      </div>

      <div className="dashboardShadow p-8 dark:border rounded-2xl dark:border-[#5E5E5E] dark:text-white text-flowGray flex flex-col justify-start items-start lg:w-2/3 h-full">
        <h2 className="text-flow-20 tracking-[0.8px] mb-3">
          Lörem ipsum monode yr. Or beledes och pretengar, dide dodoktigt.
        </h2>
        <p className="text-flow-12 font-light tracking-[0.48px] lg:max-w-[781px]">
          Dein Konto hat einen durchschnittlichen Qualitätsfaktor. Du kannst
          deine Anzeigenplatzierungen verbessern und deine Kosten pro Klick
          senken, wenn du einige Anpassungen an deinen Keywords und Landing
          Pages vornehmen.
          <br />
          <br /> Die unten stehenden Diagramme bieten wertvolle Einblicke und
          Empfehlungen, um dir zu helfen, höhere Qualitätsfaktoren zu erzielen
          und effektiver zu werben. Deine Keywords und Landing Pages vornehmen.{" "}
          <br />
          <br /> Die unten stehenden Diagramme bieten wertvolle Einblicke und
          Empfehlungen, um dir zu helfen, höhere Qualitätsfaktoren zu erzielen
          und effektiver zu werben.
        </p>
      </div>
    </div>
  );
};

export default ContactAndInfo;
