import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const services = [
  {
    title: "Wie läuft die Zusammenarbeit mit FLOW ab?",
    description:
      "Der gemeinsame Vertrag steht? Dann benötigen wir von euch ein kurzes Briefing (keine Sorge - ihr erhaltet einen Fragebogen, der euch ca. 7 Minuten Zeit kostet) und wir starten mit einer ausführlichen Analyse deines Product Fits (in Frage 2 findest du ein paar mehr Infos dazu). Danach erstellen wir eine individuelle Strategie, setzen die Kampagnen um und optimieren sie laufend. Du wirst regelmäßig über Fortschritte und Ergebnisse informiert.",
  },
  {
    title: "Welche Leistungen sind in eurem Service enthalten?",
    description: "Einen Einblick unserer Leistungen findest du hier:",
    listItems: [
      "strategische Planung",
      "Recherchen und Analysen (für Market / Product Fit, Zielgruppen, Konkurrenz, Keywords)",
      "Tracking-Setup",
      "Kampagnenerstellung",
      "laufende Optimierungen (von tägliche bis monatliche Anpassungen)",
      "wöchentliche & monatliche Reportings",
    ],
    additionalInfo: "Optional:",
    optionalItems: [
      "CRO (Conversion-Rate Optimization) auf Website / im Shop",
      "Bau / Ausbau einer Landingpage",
      "sonstige technischen Umsetzungen für deinen Shop / deine Website",
    ],
  },
  {
    title: "Kann ich meine bestehenden Kampagnen von euch überprüfen lassen?",
    description:
      "Ja, wir bieten eine umfassende Analyse bestehender Kampagnen an. Dabei prüfen wir Struktur, Performance und Optimierungspotenziale, um dir konkrete Handlungsempfehlungen zu geben.",
  },
  {
    title: "Wie wird der Erfolg meiner Kampagne gemessen?",
    description:
      "Wir messen den Erfolg anhand von Kennzahlen wie Klickrate (CTR), Conversion-Rate, Kosten pro Conversion (CPC) und ROI. Die KPIs sprechen wir vorher mit dir ab und veranschaulichen dir diese in einem regelmäßigen Reporting, das du jederzeit einsehen kannst. ",
  },
  {
    title: "Bekomme ich Einblick in die Kampagnen und deren Performance?",
    description:
      "Ja, du hast jederzeit vollständigen Zugang zu allen Google-Konten, wie Google Ads, Google Analytics etc. und erhältst regelmäßig Berichte mit den wichtigsten Kennzahlen, Trends und Optimierungsvorschlägen von uns. ",
  },
  {
    title: "Wie lange dauert die Einrichtung von (neuen) Kampagnen?",
    description:
      "Die Einrichtung dauert in der Regel 10–15 Werktage, abhängig von der Komplexität und den spezifischen Anforderungen deiner Kampagne(n). Der Zeitraum wird von uns etwas länger angesetzt, damit wir genügend Zeit für die Recherchen und Analysen haben, die wir für deine Kampagnen vornehmen. ",
  },
  {
    title: "Wie lange dauert es, bis Ergebnisse sichtbar sind?",
    description:
      "Erste Ergebnisse wie Klicks und Impressionen sind oft schon nach wenigen Stunden sichtbar, sobald die Kampagnen aktiviert wurden. Messbare Conversions (Bestellungen oder Leads) als auch Umsatzsteigerungen benötigen je nach Kampagnentyp und Zielgruppe 2–4 Wochen, da diese eine gewisse Lernphase brauchen, um ihren Algorithmus mit Daten zu „füttern“. ",
  },
  {
    title: "Muss ich mich um etwas kümmern?",
    description:
      "Nein, wir übernehmen den gesamten Prozess, von der Strategieentwicklung bis zur Optimierung. Du kannst dich voll auf dein Geschäft konzentrieren. Lediglich Freigaben oder spezifische Informationen können zur Abstimmung notwendig sein.",
  },
  {
    title: "Gibt es flexible Betreuungspauschalen?",
    description:
      "Ja, wir bieten flexible Modelle, die sich an deinem Budget und deinen Zielen orientieren – von projektbasierten Lösungen bis hin zu laufender Betreuung. Schreib uns gerne & wir besprechen mit dir, was für dich/euch am sinnvollsten sein kann.",
  },
  {
    title: "Wird meine Website / mein Shop mit betreut?",
    description:
      "Im Rahmen der Kampagnen-Optimierung beraten wir dich zu Landingpages und Conversion-Optimierung. Technische Anpassungen an der Website übernehmen wir auf Anfrage.",
  },
  {
    title: "Arbeitet ihr mit Google zusammen?",
    description:
      "Ja, wir sind zertifizierte Google Ads-Experten und setzen die aktuellen Best Practices direkt um. Unser Wissen basiert auf den offiziellen Google-Richtlinien und langjähriger Erfahrung.Zudem sind wir durch unsere Zusammenarbeit mit Google regelmäßig bei Google in der ABC-Straße in Hamburg vor Ort, um einen persönlichen Austausch gewährleisten zu können.",
  },
];

const FaqAccordion = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const renderDescription = (service) => {
    if (service.listItems) {
      return (
        <div className="space-y-4">
          <p className="text-[#F9F8F7] text-flow-20 font-light tracking-wide leading-snug">
            {service.description}
          </p>
          <ul className="list-disc pl-6 space-y-2">
            {service.listItems.map((item, index) => (
              <li
                key={index}
                className="text-[#F9F8F7] text-flow-20 font-light tracking-wide leading-snug"
              >
                {item}
              </li>
            ))}
          </ul>
          {service.additionalInfo && (
            <>
              <p className="text-[#F9F8F7] text-flow-20 font-light tracking-wide leading-snug mt-4">
                {service.additionalInfo}
              </p>
              <ul className="list-disc pl-6 space-y-2">
                {service.optionalItems.map((item, index) => (
                  <li
                    key={index}
                    className="text-[#F9F8F7] text-flow-20 font-light tracking-wide leading-snug"
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      );
    }
    return (
      <p className="text-[#F9F8F7] text-flow-20 font-light tracking-wide leading-snug">
        {service.description}
      </p>
    );
  };

  return (
    <div
      className="max-w-[1216px] mx-auto py-16 px-4 flex flex-col gap-14"
      id="faq"
    >
      <h2 className="text-4xl font-light mb-12 text-flowWhite w-full lg:text-right text-center">
        Hast du noch <span className="text-flowPrimary">Fragen?</span>
      </h2>

      <div className="space-y-8">
        {services.map((service, index) => (
          <motion.div key={index} className="pb-8 relative" initial={false}>
            <motion.button
              className="w-full flex justify-between items-center cursor-pointer text-left"
              onClick={() =>
                setExpandedIndex(expandedIndex === index ? null : index)
              }
              aria-expanded={expandedIndex === index}
            >
              <h3 className="text-2xl text-white">{service.title}</h3>
              <motion.span
                className="text-3xl text-flowWhite"
                animate={{ rotate: expandedIndex === index ? 45 : 0 }}
                transition={{ duration: 0.3 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M9.00091 17.4853L9.00091 0.514719M0.515625 9H17.4862"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </motion.span>
            </motion.button>

            <AnimatePresence initial={false}>
              {expandedIndex === index && (
                <>
                  <motion.div
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto", marginTop: "1rem" },
                      collapsed: { opacity: 0, height: 0, marginTop: 0 },
                    }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                    className="overflow-hidden"
                  >
                    {renderDescription(service)}
                  </motion.div>
                  <motion.div
                    key="border"
                    className="absolute bottom-0 left-0 h-[1px] bg-flowPrimary"
                    initial={{ width: 0 }}
                    animate={{ width: "100%" }}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                  />
                </>
              )}
            </AnimatePresence>
            {expandedIndex !== index && (
              <div className="absolute bottom-0 left-0 w-full h-[1px] bg-[#333]" />
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default FaqAccordion;
