import React, { useState, useEffect } from "react";

const CustomGauge = ({ value, min = 0, max = 10 }) => {
  const [displayValue, setDisplayValue] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayValue(value);
    }, 500);
    return () => clearTimeout(timer);
  }, [value]);

  const percentage = ((displayValue - min) / (max - min)) * 100;
  const startAngle = -90;
  const endAngle = startAngle + (180 * percentage) / 100;

  const getGradientId = (percent) => {
    if (percent <= 40) return "redGradient";
    if (percent <= 80) return "yellowGradient";
    return "greenGradient";
  };

  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  const describeArc = (x, y, radius, startAngle, endAngle) => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    return [
      "M",
      start.x,
      start.y,
      "A",
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y,
    ].join(" ");
  };

  return (
    <div className="w-full h-full relative">
      <svg
        className="w-full h-full"
        viewBox="0 0 200 100"
        preserveAspectRatio="xMidYMid meet"
      >
        <defs>
          <linearGradient id="redGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="35.78%" stopColor="rgba(255, 189, 0, 1)" />
            <stop offset="58.59%" stopColor="rgba(255, 59, 48, 1)" />
          </linearGradient>
          <linearGradient
            id="yellowGradient"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
          >
            <stop offset="0%" stopColor="rgba(39, 174, 96, 1)" />
            <stop offset="10%" stopColor="rgba(255, 189, 0, 1)" />
          </linearGradient>
          <linearGradient
            id="greenGradient"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
          >
            <stop offset="0%" stopColor="#27AE60" />
            <stop offset="100%" stopColor="#27AE60" />
          </linearGradient>
          <radialGradient id="overlayGradient" cx="5.95%" cy="7.14%" r="100%">
            <stop offset="0%" stopColor="rgba(255, 255, 255, 0.50)" />
            <stop offset="0" stopColor="rgba(255, 255, 255, 0.00)" />
          </radialGradient>
        </defs>
        {/* Background arc */}
        <path
          d={describeArc(100, 100, 90, startAngle, startAngle + 180)}
          fill="none"
          strokeWidth="10"
          className="stroke-[#E6E6E5] dark:stroke-[#222121]"
        />
        {/* Colored arc */}
        <path
          d={describeArc(100, 100, 90, startAngle, endAngle)}
          fill="none"
          stroke={`url(#${getGradientId(percentage)})`}
          strokeWidth="10"
          strokeLinecap="round"
        />
        {/* Overlay gradient */}
        <path
          d={describeArc(100, 100, 90, startAngle, endAngle)}
          fill="none"
          stroke="url(#overlayGradient)"
          strokeWidth="10"
          strokeLinecap="round"
        />
      </svg>
      <div className="absolute top-1/3 right-1/2 translate-x-1/2 translate-y-1/2 flex flex-col items-center justify-center dark:text-white text-flowGray">
        <div className="text-[32px] tracking-[1.28px]">{value}/10</div>
        <div className="text-[14px] tracking-[0.56px] leading-normal">
          Qualitätsfaktor
        </div>
      </div>
    </div>
  );
};

const CategoryDropdown = ({ category, isOpen, toggleOpen }) => {
  const getScoreColor = (score) => {
    if (score <= 3) return "bg-red-500";
    if (score <= 7) return "bg-yellow-500";
    return "bg-green-500";
  };

  const scoreColor = getScoreColor(category.score);
  const textColor =
    category.score <= 3
      ? "text-red-500"
      : category.score <= 7
      ? "text-yellow-500"
      : "text-green-500";

  return (
    <div className="border-t border-[#E0E0E0] dark:border-white/10 py-2">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={toggleOpen}
      >
        <div className="flex items-center">
          <div
            className={`rounded-full p-3 ${scoreColor} mr-2 text-[10px] leading-[0.4px] w-10 h-10 flex justify-center items-center dark:bg-opacity-40 bg-opacity-20`}
          >
            <span className={textColor}>{category.score}/10</span>
          </div>
          <h3 className="text-sm font-medium dark:text-flowWhite text-flowGray">
            {category.name}
          </h3>
        </div>
        <div className="flex items-center dark:text-flowWhite text-flowGray">
          <span>{isOpen ? "▲" : "▼"}</span>
        </div>
      </div>
      {isOpen && (
        <p className="text-sm text-gray-400 mt-2">{category.summary}</p>
      )}
    </div>
  );
};

const SummarySection = ({ analysisData }) => {
  const [openCategory, setOpenCategory] = useState(0);

  useEffect(() => {
    console.log("analysisData in SummarySection:", analysisData);
  }, [analysisData]);

  const toggleCategory = (index) => {
    setOpenCategory(openCategory === index ? null : index);
  };

  if (!analysisData) {
    return <div>Loading analysis data...</div>;
  }

  const { overall_score = 0, categories = [] } =
    analysisData.analysis || analysisData;

  return (
    <div className="p-6 text-white rounded-lg h-full flex flex-col dashboardShadow dark:border dark:border-[#5E5E5E]">
      <div className="w-full h-[147px] md:h-[206px] max-w-[449px] mx-auto">
        <CustomGauge value={overall_score} />
      </div>
      <div className="space-y-2 flex-grow overflow-auto">
        {categories.map((category, index) => (
          <CategoryDropdown
            key={index}
            category={category}
            isOpen={openCategory === index}
            toggleOpen={() => toggleCategory(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default SummarySection;
