import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination, Autoplay } from "swiper/modules";
import { motion, AnimatePresence } from "framer-motion";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

const sliderData = [
  {
    title: "PERFORMANCEMAX",
    description:
      "Relevante Zielgruppen durch eine Kampagne über alle Google-Kanäle hinweg erreichen. Es werden verschiedene Kampagnentypen kombiniert und alle wichtigen Google Werbenetzwerke ausgespielt. Abgedeckt werden das Suchnetzwerk, das Displaynetzwerk, Discovery, YouTube und Google Maps. Dieser Kampagnentyp löst zudem Smart Shopping und lokale Kampagnen ab und ist global sowie in allen Sprachen verfügbar.",
    subDescription:
      "PerformanceMax Kampagnen eignen sich zur Lead-Gewinnung sowie zur Steigerung der Online- und Offline-Verkäufe. Erfahrungsgemäß wird hiermit auch eine große Reichweite erzielt.",
    mainImage: "images/phone-image.png",
    topImages: ["images/top-image-1.png", "images/top-image-2.png"],
    bubbles: ["Umsatz", "Reichweite", "Traffic"],
  },
  {
    title: "PERFORMANCEMAX",
    description:
      "Relevante Zielgruppen durch eine Kampagne über alle Google-Kanäle hinweg erreichen. Es werden verschiedene Kampagnentypen kombiniert und alle wichtigen Google Werbenetzwerke ausgespielt. Abgedeckt werden das Suchnetzwerk, das Displaynetzwerk, Discovery, YouTube und Google Maps. Dieser Kampagnentyp löst zudem Smart Shopping und lokale Kampagnen ab und ist global sowie in allen Sprachen verfügbar.",
    subDescription:
      "PerformanceMax Kampagnen eignen sich zur Lead-Gewinnung sowie zur Steigerung der Online- und Offline-Verkäufe. Erfahrungsgemäß wird hiermit auch eine große Reichweite erzielt.",
    mainImage: "images/phone-image.png",
    topImages: ["images/top-image-1.png", "images/top-image-2.png"],
    bubbles: ["Umsatz", "Reichweite", "Traffic"],
  },
  {
    title: "PERFORMANCEMAX",
    description:
      "Relevante Zielgruppen durch eine Kampagne über alle Google-Kanäle hinweg erreichen. Es werden verschiedene Kampagnentypen kombiniert und alle wichtigen Google Werbenetzwerke ausgespielt. Abgedeckt werden das Suchnetzwerk, das Displaynetzwerk, Discovery, YouTube und Google Maps. Dieser Kampagnentyp löst zudem Smart Shopping und lokale Kampagnen ab und ist global sowie in allen Sprachen verfügbar.",
    subDescription:
      "PerformanceMax Kampagnen eignen sich zur Lead-Gewinnung sowie zur Steigerung der Online- und Offline-Verkäufe. Erfahrungsgemäß wird hiermit auch eine große Reichweite erzielt.",
    mainImage: "images/phone-image.png",
    topImages: ["images/top-image-1.png", "images/top-image-2.png"],
    bubbles: ["Umsatz", "Reichweite", "Traffic"],
  },
  // Add more slider items here...
];

const GoogleAdsSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const bubbleVariants = {
    hidden: { opacity: 0, scale: 0.5, y: 20 },
    visible: (index) => ({
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
        delay: index * 0.15,
      },
    }),
    exit: {
      opacity: 0,
      scale: 0.5,
      y: -20,
      transition: { duration: 0.2 },
    },
  };

  const imageVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 20,
        delay: index * 0.2,
      },
    }),
    exit: {
      opacity: 0,
      y: -20,
      transition: { duration: 0.2 },
    },
  };

  return (
    <div className="relative dark:text-white lg:py-28 p-8 overflow-hidden">
      <Swiper
        modules={[EffectFade, Pagination, Autoplay]}
        effect="fade"
        pagination={{
          clickable: true,
          el: ".custom-pagination",
          bulletClass: "swiper-pagination-bullet",
          bulletActiveClass: "swiper-pagination-bullet-active",
        }}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        className="w-full"
      >
        {sliderData.map((slide, index) => (
          <SwiperSlide key={index} className="bg-transparent">
            <div className="flex lg:flex-row flex-col gap-8">
              <div className="lg:w-1/2 relative flex items-center justify-center min-h-[453px]">
                <motion.img
                  src={slide.mainImage}
                  alt="Main visual"
                  className="w-auto lg:h-[453px] h-[350px] object-contain"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                />
                <AnimatePresence mode="wait">
                  {activeIndex === index && (
                    <>
                      {slide.topImages.map((img, imgIndex) => (
                        <motion.img
                          key={`top-${imgIndex}`}
                          src={img}
                          alt={`Feature ${imgIndex + 1}`}
                          className="absolute w-40 h-auto"
                          style={{
                            [imgIndex === 0 ? "left" : "right"]: "10%",
                            top: imgIndex === 0 ? "15%" : "25%",
                          }}
                          variants={imageVariants}
                          initial="hidden"
                          animate="visible"
                          exit="exit"
                          custom={imgIndex}
                        />
                      ))}
                      {slide.bubbles.map((bubble, bubbleIndex) => (
                        <motion.div
                          key={`bubble-${bubbleIndex}`}
                          className="absolute bg-gradient-to-r from-blue-400 to-blue-600 text-white px-6 py-2 rounded-xl shadow-lg"
                          style={{
                            top: `${25 + bubbleIndex * 25}%`,
                            [bubbleIndex % 2 === 0 ? "left" : "right"]: "15%",
                          }}
                          variants={bubbleVariants}
                          initial="hidden"
                          animate="visible"
                          exit="exit"
                          custom={bubbleIndex}
                        >
                          {bubble}
                        </motion.div>
                      ))}
                    </>
                  )}
                </AnimatePresence>
              </div>

              <div className="lg:w-1/2 space-y-6">
                <h2 className="text-4xl font-light">
                  Unsere Google Ads Leistungen
                </h2>
                <div className="flex items-center gap-3 flowContactGradient bg-clip-text	">
                  <svg
                    className="w-7 h-7 text-blue-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M14.5006 2C14.368 2 14.2408 2.05268 14.1471 2.14645C14.0533 2.24021 14.0006 2.36739 14.0006 2.5C14.0006 2.63261 14.0533 2.75979 14.1471 2.85355C14.2408 2.94732 14.368 3 14.5006 3H16.2936L11.5006 7.793L8.85463 5.146C8.80819 5.09944 8.75301 5.06249 8.69226 5.03729C8.63152 5.01208 8.5664 4.99911 8.50063 4.99911C8.43486 4.99911 8.36974 5.01208 8.309 5.03729C8.24825 5.06249 8.19308 5.09944 8.14663 5.146L3.14663 10.146C3.05274 10.2399 3 10.3672 3 10.5C3 10.6328 3.05274 10.7601 3.14663 10.854C3.24052 10.9479 3.36786 11.0006 3.50063 11.0006C3.63341 11.0006 3.76075 10.9479 3.85463 10.854L8.50063 6.207L11.1466 8.854C11.1931 8.90056 11.2483 8.93751 11.309 8.96271C11.3697 8.98792 11.4349 9.00089 11.5006 9.00089C11.5664 9.00089 11.6315 8.98792 11.6923 8.96271C11.753 8.93751 11.8082 8.90056 11.8546 8.854L17.0006 3.707V5.5C17.0006 5.63261 17.0533 5.75979 17.1471 5.85355C17.2408 5.94732 17.368 6 17.5006 6C17.6332 6 17.7604 5.94732 17.8542 5.85355C17.948 5.75979 18.0006 5.63261 18.0006 5.5V2.5C18.0006 2.36739 17.948 2.24021 17.8542 2.14645C17.7604 2.05268 17.6332 2 17.5006 2H14.5006Z" />
                  </svg>
                  <h3 className="text-2xl bg-gradient-to-r from-blue-400 to-blue-600 bg-clip-text text-transparent">
                    {slide.title}
                  </h3>
                </div>
                <div className="space-y-4">
                  <p className="text-gray-800 dark:text-gray-200 leading-relaxed">
                    {slide.description}
                  </p>
                  <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                    {slide.subDescription}
                  </p>
                </div>
                <a
                  href="#contact"
                  className="flex items-center gap-2 text-center flowContactGradient dashboardShadow  hover:bg-blue-700 text-white px-6 py-3 rounded-xl transition-colors duration-300 max-w-48"
                >
                  Termin vereinbaren
                </a>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="custom-pagination flex justify-center items-center gap-2 mt-8">
        {sliderData.map((_, index) => (
          <motion.button
            key={index}
            className={`h-2 rounded-full bg-blue-400 cursor-pointer transition-all duration-300 ${
              index === activeIndex ? "bg-blue-600" : "opacity-50"
            }`}
            animate={{
              width: index === activeIndex ? 32 : 8,
            }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 30,
            }}
            onClick={() => setActiveIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default GoogleAdsSlider;
