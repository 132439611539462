import React, { useState } from "react";
import CustomButton from "../components/CustomButton";

const ExpandIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
  >
    <path
      d="M6.00156 2.2998C6.49862 2.2998 6.90156 2.70275 6.90156 3.1998C6.90156 3.69686 6.49862 4.0998 6.00156 4.0998C5.50451 4.0998 5.10156 3.69686 5.10156 3.1998C5.10156 2.70275 5.50451 2.2998 6.00156 2.2998Z"
      fill="#BDBDBD"
    />
    <path
      d="M6.00156 5.5998C6.49862 5.5998 6.90156 6.00275 6.90156 6.4998C6.90156 6.99686 6.49862 7.39981 6.00156 7.39981C5.50451 7.39981 5.10156 6.99686 5.10156 6.4998C5.10156 6.00275 5.50451 5.5998 6.00156 5.5998Z"
      fill="#BDBDBD"
    />
    <path
      d="M6.90156 9.79981C6.90156 9.30275 6.49862 8.89981 6.00156 8.89981C5.50451 8.89981 5.10156 9.30275 5.10156 9.79981C5.10156 10.2969 5.50451 10.6998 6.00156 10.6998C6.49862 10.6998 6.90156 10.2969 6.90156 9.79981Z"
      fill="#BDBDBD"
    />
  </svg>
);

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M9 3L3 9M3 3L9 9"
      stroke="#BDBDBD"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const KpiCard = ({ title, value, change, info, expandInfo }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div className="p-6 lg:w-[240px] rounded-2xl dark:border dark:border-[#5E5E5E] dashboardShadow text-white flex-shrink-0 flex flex-col gap-2">
      <div className="flex justify-between items-center mb-2 w-full">
        <h3 className="text-sm font-medium font-inter text-[#828282] dark:text-white/60">
          {title}
        </h3>
        <button className="text-gray-400 hover:text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div className="flex items-baseline border-b dark:border-[#5C5C5C] border-[#E0E0E0] pb-2 w-full justify-between">
        <span className="text-flow-20 font-semibold text-flowGray dark:text-white font-inter">
          {value}
        </span>
        <span
          className={`ml-2 text-sm ${
            change >= 0 ? "text-green-400" : "text-red-400"
          }`}
        >
          {change >= 0 ? "+" : ""}
          {change}%
        </span>
      </div>
      <div className="flex flex-col w-full">
        <div className="flex flex-row  items-center justify-between w-full">
          <button
            className="mt-1 text-xs dark:text-white text-flowGray transition-colors duration-300 font-inter"
            onClick={toggleExpand}
            aria-expanded={isExpanded}
          >
            {info}
          </button>
          <button
            onClick={toggleExpand}
            aria-label={isExpanded ? "Collapse" : "Expand"}
          >
            {isExpanded ? <CloseIcon /> : <ExpandIcon />}
          </button>
        </div>
        {isExpanded && (
          <div className="mt-4 text-xs text-gray-400 flex flex-col gap-4 ">
            <p className="font-inter font-light text-flow-10">{expandInfo}</p>
            <CustomButton text="Termin vereinbaren" />
          </div>
        )}
      </div>
    </div>
  );
};

export default KpiCard;
