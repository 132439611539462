import React, { useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X } from "lucide-react";
import ReactPlayer from "react-player";

const VideoPlayerWithLightbox = () => {
  const [showLightbox, setShowLightbox] = useState(false);
  const [showBubble, setShowBubble] = useState(false);
  const [bubblePosition, setBubblePosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);
  const lightboxRef = useRef(null);

  const handleMouseMove = (e) => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setBubblePosition({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
    }
  };

  const handleMouseEnter = () => setShowBubble(true);
  const handleMouseLeave = () => setShowBubble(false);

  const openLightbox = () => setShowLightbox(true);
  const closeLightbox = (e) => {
    if (e.target === lightboxRef.current) {
      setShowLightbox(false);
    }
  };

  return (
    <>
      <div
        ref={containerRef}
        className="relative mx-auto lg:max-w-[1216px] lg:w-[1216px] w-full h-[550px] lg:h-[400px] hover:cursor-none rounded-xl mt-5 overflow-hidden"
        onMouseMove={handleMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={openLightbox}
      >
        <img
          src="/images/video-thumbnail.png"
          alt="Video thumbnail"
          className="w-full h-full object-cover rounded-xl"
        />
        {showBubble && (
          <div
            className="absolute pointer-events-none transition-all duration-100 ease-out"
            style={{
              left: `${bubblePosition.x}px`,
              top: `${bubblePosition.y}px`,
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="relative w-[220px] h-[220px]">
              <div className="absolute inset-0 rounded-full border-[0.5px] border-[rgba(181,_221,_255,_0.40)] opacity-30 animate-pulsate"></div>
              <div className="absolute inset-[15px] rounded-full border-[0.5px] border-[rgba(181,_221,_255,_0.40)] opacity-60 animate-pulsate"></div>
              <div className="absolute inset-[30px] rounded-full border-[0.5px] border-[rgba(181,_221,_255,_0.40)] opacity-90 animate-pulsate"></div>
              <div className="absolute inset-[30px] bg-white rounded-full shadow-lg flex flex-col justify-center items-center w-40 h-40">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M21.5925 11.5531L8.08502 3.29274C7.91436 3.18688 7.71831 3.12899 7.51751 3.1252C7.31672 3.1214 7.11862 3.17184 6.94408 3.27118C6.77081 3.3664 6.62647 3.50665 6.52631 3.67711C6.42615 3.84757 6.37388 4.04191 6.37502 4.23962V20.7602C6.37388 20.958 6.42615 21.1523 6.52631 21.3228C6.62647 21.4932 6.77081 21.6335 6.94408 21.7287C7.11862 21.828 7.31672 21.8785 7.51751 21.8747C7.71831 21.8709 7.91436 21.813 8.08502 21.7071L21.5925 13.4468C21.7551 13.348 21.8895 13.2089 21.9827 13.0431C22.076 12.8773 22.125 12.6902 22.125 12.4999C22.125 12.3097 22.076 12.1226 21.9827 11.9568C21.8895 11.7909 21.7551 11.6519 21.5925 11.5531Z"
                    fill="#333333"
                  />
                </svg>
                <p className="text-flowGray text-sm mt-2">Video anschauen</p>
              </div>
            </div>
          </div>
        )}
      </div>

      <AnimatePresence>
        {showLightbox && (
          <motion.div
            ref={lightboxRef}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 "
            onClick={closeLightbox}
          >
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
              transition={{ type: "spring", damping: 30, stiffness: 300 }}
              className="relative w-full h-full max-w-6xl mx-auto max-h-[500px]"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={() => setShowLightbox(false)}
                className="absolute -top-10 right-4 text-white hover:text-flowPrimary transition-colors z-10"
              >
                <X size={24} />
              </button>
              <ReactPlayer
                url="/videos/Optimized_LP.mp4"
                className="react-player "
                width="100%"
                height="100%"
                controls
                loop="true"
                playing
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default VideoPlayerWithLightbox;
