import React, { useRef, useState } from "react";
import { Download } from "lucide-react";

const PDFDownloadDashboard = ({ analysisData, dashboardData }) => {
  const [isGenerating, setIsGenerating] = useState(false);

  const formatDate = (date) => {
    return new Intl.DateTimeFormat("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(date);
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const generatePDF = async () => {
    if (isGenerating) return;
    setIsGenerating(true);

    try {
      const [{ default: html2canvas }, { default: jsPDF }] = await Promise.all([
        import("html2canvas"),
        import("jspdf"),
      ]);

      // Get the sections we want to capture
      const sections = [
        document.getElementById("quality-score-section"),
        document.getElementById("kpis-section"),
        // Add more sections as needed
      ];

      if (!sections.every(Boolean)) {
        throw new Error("Some sections could not be found");
      }

      // Initialize PDF
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        compress: true,
      });

      const pageWidth = pdf.internal.pageSize.getWidth();
      const margin = 10;
      let currentY = margin;

      // Process each section
      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];

        // Temporarily modify styles for capture
        const originalBackground = section.style.background;
        section.style.background = "transparent";

        // Force dark mode classes
        section.classList.add("dark");

        const canvas = await html2canvas(section, {
          scale: 2,
          useCORS: true,
          allowTaint: true,
          backgroundColor: "#1a1b1d", // Dark background
          logging: false,
        });

        // Restore original styles
        section.style.background = originalBackground;
        section.classList.remove("dark");

        // Calculate dimensions while maintaining aspect ratio
        const imgWidth = pageWidth - 2 * margin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Add new page if needed
        if (
          i > 0 &&
          currentY + imgHeight > pdf.internal.pageSize.getHeight() - margin
        ) {
          pdf.addPage();
          currentY = margin;
        }

        // Add image to PDF
        pdf.addImage(
          canvas.toDataURL("image/jpeg", 1.0),
          "JPEG",
          margin,
          currentY,
          imgWidth,
          imgHeight,
          undefined,
          "FAST"
        );

        currentY += imgHeight + margin;

        // Small delay between processing sections
        await delay(50);
      }

      pdf.save(`Flow-Google-Ads-Analyse-${formatDate(new Date())}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
      alert(
        "Beim Erstellen des PDFs ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
      );
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="flex justify-end mb-8 px-4">
      <button
        onClick={generatePDF}
        disabled={isGenerating}
        className={`flex items-center gap-2 ${
          isGenerating
            ? "bg-gray-400"
            : "flowContactGradient dashboardShadow  hover:bg-blue-700"
        } text-white px-6 py-3 rounded-xl transition-colors duration-300`}
      >
        <Download className="h-5 w-5" />
        <span className="font-light">
          {isGenerating ? "Wird generiert..." : "Als PDF herunterladen"}
        </span>
      </button>
    </div>
  );
};

export default PDFDownloadDashboard;
