import React from "react";

const UserInfo = ({ userInfo, onLogout }) => {
  if (!userInfo) return null;

  const getInitials = (name) => {
    return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  return (
    <div className="flex items-center space-x-4">
      {userInfo.picture ? (
        <img
          src={userInfo.picture}
          alt={userInfo.name}
          className="w-10 h-10 rounded-full"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><rect width="40" height="40" rx="20" fill="%23007bff"/><text x="50%" y="50%" font-family="Arial" font-size="16" fill="%23ffffff" text-anchor="middle" dy=".3em">${getInitials(
              userInfo.name
            )}</text></svg>`;
          }}
        />
      ) : (
        <div className="w-10 h-10 rounded-full bg-blue-500 flex items-center justify-center text-white font-medium">
          {getInitials(userInfo.name)}
        </div>
      )}
      <div>
        <span className="text-sm font-medium text-[#333] dark:text-white">
          {userInfo.name}
        </span>
        <p className="text-xs text-gray-500 dark:text-gray-400">
          {userInfo.email}
        </p>
      </div>
      <button
        onClick={onLogout}
        className="text-sm text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
      >
        Logout
      </button>
    </div>
  );
};

export default UserInfo;
