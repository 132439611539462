import React, { useState, useEffect, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import GoogleLogo from "./GoogleLogo";
import VideoPlayerWithLightbox from "./VideoPlayer";
import { Link } from "react-router-dom";
const keywords = [
  "Kampagnen",
  "Strategien",
  "Performance",
  "ROI",
  "Conversions",
];

const AnimatedKeyword = ({ word }) => (
  <motion.span
    key={word}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.5 }}
    className="inline-block"
  >
    {word}
  </motion.span>
);

const LandingPageHeroVideo = () => {
  return (
    <section className="lg:mt-20 flex flex-col justify-center items-center  px-4">
      <div className="flex flex-col justify-center items-center gap-9">
        <h1 className="text-flowWhite text-center lg:text-fluid-36-48 text-flow-36 lg:max-w-[1052px] font-light leading-normal">
          Kostenloses Google Ads Audit:
          <br></br>
          In 2 Minuten zu direkt umsetzbaren Empfehlungen
        </h1>

        <p className="text-center lg:text-flow-20 text-flow-16 leading-normal max-w-4xl font-light tracking-wider text-[#F9F8F7]">
          Die größten Herausforderungen bei Google Ads: Die richtige Zielgruppe
          zu erreichen, hohe Kosten und wenig Transparenz – unser{" "}
          <b>kostenloses Audit</b> unterstützt dich dabei, diese Potenziale voll
          auszuschöpfen und Google in einen profitablen Kanal zu verwandeln.
        </p>
        <div className="flex items-center justify-center gap-4">
          <div className="md:w-[155px] w-[115px]">
            <GoogleLogo mobileWidth="100%" desktopWidth="100%" />
          </div>
          <Link
            to={"#register"}
            className="flex flex-row justify-center items-center gap-1 px-6 py-4 bg-[#B5DDFF] rounded-xl text-[#333] flowGradient transition-all text-flow-14 leading-normal"
          >
            <span className="font-sans">Sicher Anmelden</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="4"
              viewBox="0 0 19 4"
              fill="none"
            >
              <path
                d="M18.8057 2.17678C18.9033 2.07915 18.9033 1.92085 18.8057 1.82322L17.2147 0.232233C17.1171 0.134602 16.9588 0.134602 16.8611 0.232233C16.7635 0.329864 16.7635 0.488155 16.8611 0.585786L18.2754 2L16.8611 3.41421C16.7635 3.51184 16.7635 3.67014 16.8611 3.76777C16.9588 3.8654 17.1171 3.8654 17.2147 3.76777L18.8057 2.17678ZM0.628906 2.25H18.6289V1.75H0.628906V2.25Z"
                fill="#333333"
              />
            </svg>
          </Link>
        </div>

        <VideoPlayerWithLightbox />
      </div>
    </section>
  );
};

export default LandingPageHeroVideo;
